import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '../../services/translate.service';
import { PeakButtonModule } from 'peak-ui';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { FooterClickEvent } from '../../models/footer-event.enum';
import { TranslatePipe } from '../../../core/pipes/translate.pipe';

@Component({
  selector: 'vlc-footer-section',
  standalone: true,
  imports: [PeakButtonModule, NgIf, NgTemplateOutlet, TranslatePipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  providers: [DynamicDialogRef, TranslateService],
})
export class FooterSectionComponent {
  @Output() buttonClicked: EventEmitter<FooterClickEvent> =
    new EventEmitter<FooterClickEvent>();
  @Input() cancelButtonLabel: string = 'vlc:Admin:Cancel';
  @Input() cancelButtonIcon: string = 'pi pi-times';
  @Input() submitButtonLabel: string = 'vlc:Admin:Save';
  @Input() submitButtonIcon: string = 'pi pi-check';
  @Input() isSubmitButtonDisabled: boolean = false;
  @Input() isCancelButtonDisabled: boolean = false;
  @Input() isExternalButtonDisabled: boolean = false;
  @Input() externalButtonLabel: string = '';
  @Input() externalButtonIcon: string = '';
  @Input() isDialog: boolean = false;
  @Input() footerTemplate?: TemplateRef<HTMLElement>;

  cancel() {
    this.buttonClicked.emit(FooterClickEvent.Close);
  }

  submit() {
    this.buttonClicked.emit(FooterClickEvent.Submit);
  }

  external() {
    this.buttonClicked.emit(FooterClickEvent.External);
  }
}
