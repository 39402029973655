<div class="main">
  <div class="action-buttons" [class.isDialog]="isDialog">
    <div class="actions-wrapper" [class.isDialog]="isDialog">
      <div class="action-buttons">
        <div class="submit-close-button" *ngIf="submitButtonLabel">
          <pk-button
            [label]="submitButtonLabel | translate"
            (click)="submit()"
            [disabledButton]="isSubmitButtonDisabled"
            [icon]="submitButtonIcon"
          ></pk-button>
        </div>
        <div class="cancel-button" *ngIf="cancelButtonLabel">
          <pk-button
            [label]="cancelButtonLabel | translate"
            (click)="cancel()"
            color="secondary"
            [disabledButton]="isCancelButtonDisabled"
            [icon]="cancelButtonIcon"
          ></pk-button>
        </div>
        <div class="continue-button" *ngIf="externalButtonLabel">
          <pk-button
            [label]="externalButtonLabel | translate"
            (click)="external()"
            color="secondary"
            [disabledButton]="isExternalButtonDisabled"
            [icon]="externalButtonIcon"
          ></pk-button>
        </div>
      </div>

      <div class="footer-template" *ngIf="footerTemplate">
        <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
      </div>
    </div>
  </div>
</div>
